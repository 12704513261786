<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 511.999 511.999"
    style="enable-background:new 0 0 511.999 511.999;"
    xml:space="preserve"
  >
    <path
      style="fill:#65B4BB;"
      d="M286.698,91.304c86.637,46.934,145.469,138.644,145.469,244.105c0,1.838-0.015,3.659-0.058,5.497
	c-4.417,228.126-347.801,228.126-352.233,0c-0.03-1.838-0.043-3.659-0.043-5.497c0-105.46,58.817-197.171,145.454-244.104"
    />
    <path
      style="fill:#57A3A7;"
      d="M256.002,511.993c-86.958,0-173.903-57.019-176.115-171.083c-0.04-1.835-0.054-3.67-0.054-5.505
	c0-105.456,58.827-197.163,145.461-244.103h12.103c-52.512,46.94-88.145,138.647-88.145,244.103c0,1.835,0,3.67,0.027,5.505
	C150.613,454.975,203.314,511.993,256.002,511.993z"
    />
    <path
      style="fill:#65B4BB;"
      d="M269.189,69.796C305.349,53.389,337.366,29.467,363.244,0H148.757
	c25.878,29.467,57.896,53.389,94.042,69.796"
    />
    <path
      style="fill:#57A3A7;"
      d="M213.52,0c10.254,29.467,22.937,53.389,37.252,69.796h-7.974
	C206.652,53.389,174.635,29.467,148.757,0H213.52z"
    />
    <path
      style="fill:#FED159;"
      d="M306.298,63.055h-100.6c-9.663,0-17.495,7.834-17.495,17.495l0,0c0,9.663,7.834,17.495,17.495,17.495
	h100.6c9.663,0,17.496-7.834,17.496-17.495l0,0C323.794,70.889,315.96,63.055,306.298,63.055z"
    />
    <polygon
      style="fill:#57A3A7;"
      points="319.648,263.637 319.648,236.653 269.489,236.653 269.489,201.891 242.504,201.891 
	242.504,236.653 192.345,236.653 192.345,324.469 292.663,324.469 292.663,358.316 192.345,358.316 192.345,385.3 242.504,385.3 
	242.504,420.062 269.489,420.062 269.489,385.3 319.648,385.3 319.648,297.484 219.33,297.484 219.33,263.637 "
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
